import React from "react";
import logo from "../assets/images/logo.png";

export const HomeScreen = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <img src={logo} alt="logo" />
    </div>
  );
};
