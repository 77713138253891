import React from "react";

export const MediumLogo = () => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83999 13.7997C5.83999 14.1381 5.6589 14.34 5.4021 14.34C5.31155 14.34 5.21061 14.3148 5.10374 14.2613L0.962333 12.1639C0.718895 12.0407 0.519989 11.7141 0.519989 11.4395V1.15875C0.519989 0.887113 0.663973 0.7268 0.870302 0.7268C0.943036 0.7268 1.02468 0.746098 1.10929 0.789144L1.25475 0.863363H1.25624L5.82069 3.17454C5.82811 3.17899 5.83405 3.18641 5.83999 3.19235V13.7997ZM10.6226 1.37547L10.9017 0.9168C11.0012 0.753519 11.1837 0.660004 11.3693 0.660004C11.4123 0.660004 11.4569 0.665941 11.4999 0.677816C11.5326 0.685238 11.5667 0.697113 11.6023 0.714925L16.4162 3.15227C16.4176 3.15227 16.4176 3.15375 16.4176 3.15375L16.4221 3.15524C16.4251 3.15672 16.4251 3.16118 16.428 3.16266C16.4518 3.18196 16.4622 3.21461 16.4458 3.24133L11.6513 11.1323L11.1615 11.9368L7.98491 5.71579L10.6226 1.37547ZM6.59999 9.63008V4.67375L10.0126 11.3594L6.94288 9.80524L6.59999 9.63008ZM16.48 13.7997C16.48 14.1173 16.2915 14.2984 16.0139 14.2984C15.8892 14.2984 15.7467 14.2628 15.5968 14.1871L14.9095 13.8383L11.8398 12.2841L16.48 4.64852V13.7997Z"
        fill="#DDDFFF"
      />
    </svg>
  );
};
