import React from "react";
import FullLogo from "../../assets/images/logo.png";
import LogoMin from "../../assets/images/symbol.png";

function MinLogo(min = false, { ...props }) {
  return (
    <img src={LogoMin} className="img-fluid" style={{ maxHeight: "75px" }} />
  );
}

export default MinLogo;
