import React from "react";
import Card from "../Card";
import { Col, Container, Row, Stack, Button } from "react-bootstrap";
import { ContainedIcon } from "../ContainedIcon";
import MedallIcon from "../../images/medall.png";
import ToolsIcon from "../../images/tools.png";
import WalletMinus from "../../images/wallet-minus.svg";
import StakeRayModal from "../StakeRayModal";
import { useDisclousure } from "../../utils/hooks/useDisclousure";
import "./style.scss";

export const StakingDropdown = ({ disclousure }) => {
  const modal = useDisclousure();

  return (
    <>
      <Container
        fluid
        className={`p-0 staking-dropdown--container ${
          disclousure.isOpen ? "active" : ""
        }`}
      >
        <Row>
          <Col sm={12} lg={6}>
            <Card className="p-3 d-flex justify-content-between mb-4 mb-lg-0">
              <div className="bordify">
                <Stack gap={4}>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="align-items-center"
                  >
                    <ContainedIcon>
                      <img src={MedallIcon} />
                    </ContainedIcon>
                    <p className="card-icon-title m-0">PENDING REWARD</p>
                  </Stack>
                  <Stack className="flex-column flex-sm-row" gap={2}>
                    <input value="00" className="input-filled  w-100 " />
                    <Button variant="gradient" className="ml-5">
                      Harvest
                    </Button>
                  </Stack>
                </Stack>
              </div>
            </Card>
          </Col>
          <Col sm={12} lg={6}>
            <Card className="p-3 d-flex justify-content-between">
              <div className="bordify">
                <Stack gap={4}>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="align-items-center"
                  >
                    <ContainedIcon>
                      <img src={ToolsIcon} />
                    </ContainedIcon>
                    <p className="card-icon-title m-0">START STAKING</p>
                  </Stack>
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={modal.onOpen}
                      variant="gradient"
                      className="ml-5 "
                    >
                      <Stack
                        direction="horizontal"
                        gap={3}
                        className="d-flex justify-content-center"
                      >
                        <img src={WalletMinus} />
                        <span>Stake SOL</span>
                      </Stack>
                    </Button>
                  </div>
                </Stack>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <StakeRayModal disclousure={modal} />
    </>
  );
};
