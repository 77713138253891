import React from "react";

export const ChartSquare = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10.11 11.15H7.45999C6.82999 11.15 6.32001 11.66 6.32001 12.29V17.41H10.11V11.15V11.15Z"
        stroke="url(#paint0_linear_1:242)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7613 6.59998H11.2412C10.6112 6.59998 10.1013 7.10999 10.1013 7.73999V17.4H13.8913V7.73999C13.8913 7.10999 13.3913 6.59998 12.7613 6.59998Z"
        stroke="url(#paint1_linear_1:242)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5481 12.85H13.8981V17.4H17.6881V13.99C17.6781 13.36 17.1681 12.85 16.5481 12.85Z"
        stroke="url(#paint2_linear_1:242)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="url(#paint3_linear_1:242)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1:242"
          x1="6.45245"
          y1="8.41841"
          x2="10.0694"
          y2="8.52684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1:242"
          x1="10.2337"
          y1="1.88723"
          x2="13.8528"
          y2="1.95012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1:242"
          x1="14.0306"
          y1="10.8645"
          x2="17.6446"
          y2="11.0136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1:242"
          x1="2.69892"
          y1="-6.72726"
          x2="21.7562"
          y2="-5.78365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
      </defs>
    </svg>
  );
};
