import React from "react";
import { Col, Container, Row, Stack, Button } from "react-bootstrap";
import Card from "../components/Card";
import MinLogo from "../components/MinLogo";
import { PageDescription } from "../components/PageDescription";
import StakeRayModal from "../components/StakeRayModal";
import { Stats } from "../components/Stats";
import { useDisclousure } from "../utils/hooks/useDisclousure";
import { ArrangeSquare } from "../components/Icons/ArrangeSquare";
import { ChartSquare } from "../components/Icons/ChartSquare";
import { MoneyTime } from "../components/Icons/MoneyTime";
import { EmptyWallet } from "../components/Icons/EmptyWallet";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { StakingDropdown } from "../components/StakingDropdown";

export const StakingScreen = () => {
  const stakingDropdown = useDisclousure();
  console.log(stakingDropdown.isOpen);

  return (
    <>
      <Stack gap={4}>
        <PageDescription>
          <PageDescription.Title>Staking</PageDescription.Title>
          <PageDescription.Description>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text{" "}
          </PageDescription.Description>
        </PageDescription>

        <Card className="p-4 d-flex justify-content-between align-items-center">
          <Container fluid className="p-0">
            <Row>
              <Col
                xs={12}
                xl={3}
                className="mb-5 mb-xl-0 d-flex justify-content-center justify-content-lg-start"
              >
                <MinLogo />
              </Col>
              <Col xl={9} className="justify-content-end d-flex">
                <Container fluid className="p-0">
                  <Row>
                    <Col xs={12} sm={6} lg={3} className="mb-2 mb-lg-0">
                      <Stats
                        icon={<EmptyWallet />}
                        title="Pending Reward"
                        number="00"
                      ></Stats>
                    </Col>
                    <Col xs={12} sm={6} lg={3} className="mb-2 mb-lg-0">
                      <Stats
                        icon={<ArrangeSquare />}
                        title="Stacked"
                        number="00"
                      ></Stats>
                    </Col>{" "}
                    <Col xs={12} sm={6} lg={3} className="mb-2 mb-lg-0">
                      <Stats
                        icon={<MoneyTime />}
                        title="APR"
                        number="21.35%"
                      ></Stats>
                    </Col>{" "}
                    <Col xs={12} sm={6} lg={3} className="mb-2 mb-lg-0">
                      <Stats
                        icon={<ChartSquare />}
                        title="Liquitity"
                        number="12,834,313,917665"
                      ></Stats>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                  variant="ghost"
                  className={`w-100 mt-4 d-xl-none chevron  ${
                    stakingDropdown.isOpen ? "active" : ""
                  }`}
                  onClick={stakingDropdown.onToggle}
                >
                  <IoChevronDownCircleOutline />
                </Button>
              </Col>
            </Row>
          </Container>
          <Button
            variant="ghost"
            className={`mx-2 d-none chevron d-xl-block ${
              stakingDropdown.isOpen ? "active" : ""
            }`}
            onClick={stakingDropdown.onToggle}
          >
            <IoChevronDownCircleOutline />
          </Button>
        </Card>

        <StakingDropdown disclousure={stakingDropdown} />
      </Stack>
    </>
  );
};
