import React from "react";

export const EmptyWallet = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 13.24V11.51C2.5 9.44001 4.18999 7.75 6.25999 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H12.26"
        stroke="url(#paint0_linear_1:184)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.5 12.41V7.84004C2.5 6.65004 3.23 5.59 4.34 5.17L12.28 2.17C13.52 1.7 14.85 2.62003 14.85 3.95003V7.75002"
        stroke="url(#paint1_linear_1:184)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
        stroke="url(#paint2_linear_1:184)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 12H14"
        stroke="url(#paint3_linear_1:184)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 16.5H8.34C8.98 16.5 9.5 17.02 9.5 17.66V18.94"
        stroke="url(#paint4_linear_1:184)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.22 15.28L3 16.5L4.22 17.72"
        stroke="url(#paint5_linear_1:184)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.5 21.78H4.16C3.52 21.78 3 21.26 3 20.62V19.34"
        stroke="url(#paint6_linear_1:184)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.28125 23.0003L9.50125 21.7803L8.28125 20.5603"
        stroke="url(#paint7_linear_1:184)"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1:184"
          x1="3.16398"
          y1="1.53183"
          x2="21.234"
          y2="2.7248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1:184"
          x1="2.93159"
          y1="-2.47705"
          x2="14.6874"
          y2="-1.78345"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1:184"
          x1="17.6089"
          y1="11.1611"
          x2="22.4895"
          y2="11.4634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1:184"
          x1="7.24462"
          y1="11.5636"
          x2="13.2139"
          y2="13.6326"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1:184"
          x1="3.22715"
          y1="15.4353"
          x2="9.32977"
          y2="16.2402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1:184"
          x1="3.04263"
          y1="14.2153"
          x2="4.20726"
          y2="14.2442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1:184"
          x1="3.22715"
          y1="18.2753"
          x2="9.32977"
          y2="19.0803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1:184"
          x1="8.32388"
          y1="19.4956"
          x2="9.48851"
          y2="19.5244"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0094FF" />
          <stop offset="1" stop-color="#FD4990" />
        </linearGradient>
      </defs>
    </svg>
  );
};
